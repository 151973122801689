.btn {
    height: 33px;
    padding: 0 24px;
    border: 0;
    background-color: #01233f;
    color: #ffffff;
    border-radius: 4px;
    margin: 35px 5%;
    width: 90%;
    cursor: pointer;
}