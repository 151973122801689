.navbar {
    width: 100%;
    height: 60px;
    background-color: #01233f;
}

.navbar_title {
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    right: 46px;
    top: 12px;
}